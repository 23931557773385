<template>
  <div class="login">
    <div class="reminder">
      <div>You are about to log in to your Canadian account.
        <a class="reminder-link" href="https://us.myaccount.gophonebox.com/">Click here to switch to a US account.</a>
      </div>
    </div>
    <!-- <div v-if="showApplicationDownMessage" class="maintenance-alert">
      <div>We are currently undergoing scheduled maintenance from 4am to 5am PST. Your service will not be interrupted.</div>
    </div> -->
    <b-container class="thisContainer">
      <!-- Sign In  -->
      <b-row class="signIn">
        <b-col
          md="7"
          sm="12"
          class="bg-white signInLeft animate__animated animate__slideInRight"
        >
          <b-form @submit="handlePreLogIn">
            <h3 class="title">Sign in to PhoneBox</h3>
            <div class="inputWrapper">
              <b-input
                v-model="username"
                type="text"
                class="my-4 p-4 pl-5"
                placeholder="Username"
                required
              ></b-input>
              <i class="far fa-user"></i>
            </div>
            <div class="inputWrapper">
              <b-input
                v-model="password"
                type="password"
                class="my-4 p-4 pl-5"
                placeholder="Password"
                required
              ></b-input>
              <i class="fas fa-lock"></i>
            </div>
            <p class="text-grey">
              Forgot your
              <router-link to="/username-recovery">
                <span class="text-grey forgetPasswordStyle">username</span>
              </router-link>
              or
              <router-link to="/password-revovery">
                <span class="text-grey forgetPasswordStyle">password</span>
              </router-link>
              ?
            </p>
            <div class="text-center mt-5">
              <button class="mybtn" style="font-size: 18px">sign in</button>
            </div>
            <hr style="width: 60%" class="mt-5 mb-4" />
            <div
              class="appleWrapper"
              id="appleid-signin"
              data-color="white"
              data-border="true"
              data-type="sign in"
              data-height="40"
            ></div>
            <p></p>
            <!-- <div class="googleWrapper" @click="showGooglePrompt">
              <div class="googleSignIn">
                  <img
                  src="../../assets/googleLogo.png"
                  style="border-radius: 50%"
                  width="20px"
                  height="20px"
                  class="mx-2"
                  alt="Google Logo"
                />
                <div>Sign in with Google</div>
              </div>
            </div> -->
            <div id="google-sign-in-phonebox">

            </div>
            <div id="google_btn">

            </div>
          </b-form>
        </b-col>
        <b-col
          md="5"
          sm="12"
          class="bg-blue text-light signInRight animate__animated animate__slideInLeft"
          style=""
        >
          <h3 style="font-size: 28px">Welcome !</h3>
          <p class="my-5">
            Don't have an account yet? Set up your PhoneBox account to get
            access to all your account information.
          </p>
          <div class="text-center">
            <button
              class="loginBtn"
              style="font-size: 18px"
              @click="$router.push('signup')"
            >
              Sign up
            </button>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import jwt from 'jsonwebtoken';

export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      showMaintenanceMessage: false,
      showApplicationDownMessage: false
    };
  },
  mounted() {
    const gClientId = "202690635151-ca3ek6ntp36qhmj096rcn3ml2e82bh3a.apps.googleusercontent.com"

    window.google.accounts.id.initialize({
      client_id: gClientId,
      callback: this.handleGoogleLogin,
      use_fedcm_for_prompt: true,
      prompt_parent_id: "google-sign-in-phonebox"
    });
    const parent = document.getElementById('google_btn');
    window.google.accounts.id.renderButton(parent, { theme: "filled_white", innerWidth: "200px" });
    window.google.accounts.id.prompt()

    if (!window.AppleID) {
      window.initAppleAuth = function() {
        const myhost = window.location.href.split("/login")[0];
        window.AppleID.auth.init({
          clientId: "com.phonebox.client",
          scope: "email",
          redirectURI: myhost,
          usePopup: true,
        });
      };
      // load Sign in with Apple JS
      let myscript = document.createElement("script");
      myscript.setAttribute(
        "src",
        "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
      );
      myscript.onload = function() {
        window.initAppleAuth();
      };
      document.head.appendChild(myscript);
      // Listen for authorization success
      document.addEventListener(
        "AppleIDSignInOnSuccess",
        this.handleAppleLogin
      );
    } else {
      window.initAppleAuth();
    }
  },
  methods: {
    /**
     * @method saveGlobalAuthData()
     * save auth data into store -> handleLogIn()
     * @param {cnum} string
     * @param {token} string
     * @param {cnumAcctOwner} string
     * @param {accountData} array containing all member data
     * No multilines: accountData.length === 1
     * Has multilines: accountData.length > 1
     */
    saveGlobalAuthData(data) {
      this.$store.commit("setLogInStates", {
        key: "token",
        value: data[0].Token,
      });
      this.$store.commit("setLogInStates", {
        key: "cnum",
        value: data[0].Cnum,
      });
      this.$store.commit("setLogInStates", {
        key: "cnumAcctOwnerSaved",
        value: data[0].Cnum,
      });
      this.$store.commit("setLogInStates", {
        key: "cnumAcctOwner",
        value: "",
      });
      if (data[0].isTmpPwd) {
        this.$router.push("/password-reset");
      } else {
        this.handleLogIn();
      }
    },
        /**
     * @method saveGlobalAuthDataSecure()
     * save auth data into store -> handleLogIn()
     * @param {cnum} string
     * @param {token} string
     * @param {cnumAcctOwner} string
     * @param {accountData} array containing all member data
     * No multilines: accountData.length === 1
     * Has multilines: accountData.length > 1
     */
     saveGlobalAuthDataSecure(data) {
      console.log(data.ClientInfoModel);
      this.$store.commit("setLogInStates", {
        key: "token",
        value: data.ClientInfoModel[0].Token,
      });
      this.$store.commit("setLogInStates", {
        key: "cnum",
        value: data.ClientInfoModel[0].Cnum,
      });
      this.$store.commit("setLogInStates", {
        key: "cnumAcctOwnerSaved",
        value: data.ClientInfoModel[0].Cnum,
      });
      this.$store.commit("setLogInStates", {
        key: "cnumAcctOwner",
        value: "",
      });
      if (data.ClientInfoModel[0].isTmpPwd) {
        this.$router.push("/password-reset");
      } else {
        this.handleLogIn();
      }
    },
    /**
     * @method showGooglePrompt()
     * Show the prompt for One Tap sign-in. 
     * If user has dismissed the notification,
     * clear the g_state cookie.
     */
    // showGooglePrompt() {
    //   window.google.accounts.id.prompt((notification) => {
    //     if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
    //       document.cookie =  `g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
    //       window.google.accounts.id.prompt()
    //     }
    //   });
    // },
    /**
     * @method handleLogIn()
     * @param {clientType} string
     * current is the previous routing path
     * check prepaid or postpaid -> save result in localstorage before login
     * type is the client type
     * if the type is prepiad -> block all postpaid paths
     * if the type is postpaid -> block all prepaid paths
     * because all prepaid paths start with 'prepaid-'
     * so current.split("-")[0] can be used to check if it is a prepaid or postpaid path
     */
    handleLogIn() {
      let current = this.$store.state.login.preRoute;
      this.$axios
        .get("Account/AccountInfo")
        .then((response) => {
          let data = JSON.parse(response.data);
          let clientType = data.Prepaid ? "prepaid" : "postpaid";
          this.$store.commit("setLogInStates", {
            key: "clientType",
            value: clientType,
          });

          this.$store.dispatch("initMultilinesData");

          // handle the routing path
          if (clientType === "prepaid") {
            if (current === null || current === "/home") {
              this.$router.push("/prepaid-home");
            } else {
              current.split("-")[0] !== "/prepaid"
                ? (current = "/page-not-found")
                : null;
              this.$router.push({ path: current });
            }
          } else {
            if (current === null) {
              this.$router.push("/home");
            } else {
              current.split("-")[0] === "/prepaid"
                ? (current = "/page-not-found")
                : null;
              this.$router.push({ path: current });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    /**
     * @method handlePreLogIn()
     * check username & password -> save auth data into store
     */
    handlePreLogIn(event) {
      event.preventDefault();
      this.$axios
        .post("/LoginRegistration/LoginVersion3", {
          Email: this.username,
          Password: this.password,
        })
        .then((response) => {
          if (response.data === "null" || !response.data.IsSuccess) {
            let errorMessage;
            if (response.data.ErrorMessage === "InvalidUserIdOrPassword") {
              errorMessage = "Your username or password is not correct."
            }
            if (response.data.ErrorMessage === "TooManyAttempts" || response.data.ErrorMessage === "UserLocked") {
              errorMessage = "You have made too many login attempts."
              this.$router.push('/too-many-attempts');
            }
            this.$notify.error({
              title: "Error!",
              message: errorMessage,
            });
          } else {
            this.saveGlobalAuthDataSecure(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$notify.error({
            title: "Error!",
            message: "Network Error!",
          });
        });
    },
    /**
     * @method handleGoogleLogin()
     * On success do something, refer to
     * https://developers.google.com/api-client-library/javascript/reference/referencedocs#googleusergetid
     * If link is outdated, refer to
     * https://developers.google.com/identity/gsi/web/reference/js-reference
     */
    handleGoogleLogin(response) {
      let user = jwt.decode(response.credential);
      let userEmail = user.email;
      let googlelogInToken = response.credential;
      this.$axios
        .post("/LoginRegistration/GoogleLogin1", {
          GoogleToken: googlelogInToken,
          Email: userEmail,
        })
        .then((response) => {
          if (response.data === "null") {
            this.$notify.error({
              title: "Error !",
              message: "Can not find your email address !",
            });
          } else {
            let data = JSON.parse(response.data);
            if (data.Type === "new") {
              this.$notify({
                type: "warning",
                title: "New User!",
                message: "Please Sign Up!",
              });
              this.$router.push("/signup");
            } else {
              this.saveGlobalAuthData(JSON.parse(response.data));
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.$notify.error({
            title: "Error !",
            message: "Network Error !",
          });
        });
    },
    handleAppleLogin(event) {
      const appleToken = event.detail;
      //console.log(`handleAppleLogin call this.$axios... w ${JSON.stringify(event.detail)}`);
      this.$axios
        .post("/LoginRegistration/AppleLogin1", appleToken)
        .then((response) => {
          // if (!response) console.log(`handleAppleLogin response not defined`); // impossible?
          // if (!response.data) {
          //   console.log(`handleAppleLogin response.data=not defined?`);
          //   this.$notify.error({
          //     title: "Error !",
          //     message: "Can not find your email address !",
          //   });
          // } else
          if (response.data === "null") {
            //console.log(`handleAppleLogin response.data=null`);
            this.$notify.error({
              title: "Error !",
              message: "Can not find your email address !",
            });
          } else {
            //console.log(`handleAppleLogin response.data=${response.data}`);
            const jsonData = JSON.parse(response.data);
            if (
              jsonData[0].Type == jsonData[0].email &&
              jsonData[0].Type.endsWith("Exception")
            ) {
              console.log(`handleAppleLogin response.data=ignorable exception`);
            } else {
              this.saveGlobalAuthData(jsonData);
            }
          }
        })
        .catch((error) => {
          console.log(`handleAppleLogin catch error=${error}`);
          this.$notify.error({
            title: "Error !",
            message: "Network Error !",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.thisContainer {
  padding-top: 4%;
  width: 840px;
}

.signInLeft {
  padding: 60px 100px;
  border-radius: 10px 0px 0px 10px;
}

.signInRight {
  padding: 60px;
  border-radius: 0px 10px 10px 0px;
}

.signUpLeft {
  padding: 60px;
  border-radius: 10px 0px 0px 10px;
}

.signUpRight {
  padding: 60px 100px;
  border-radius: 0px 10px 10px 0px;
}

.title {
  font-size: 28px;
  color: #0394ca;
}

.inputWrapper {
  position: relative;
  font-size: 18px !important;
  i {
    color: #d2d2d2;
    position: absolute;
    font-size: 18px;
    top: 16px;
    left: 16px;
  }
}

.loginBtn {
  padding: 10px 40px;
  border-radius: 50px;
  border: 2px solid #fff;
  font-weight: 500;
  color: #fff;
  outline: none !important;
  text-transform: uppercase;
  background: transparent;
}

.forgetPasswordStyle {
  font-size: 15px;
}
.forgetPasswordStyle:hover {
  opacity: 0.7s;
  cursor: pointer;
  color: #0394ca;
}

.appleWrapper {
  display: block;
  margin: 0 auto;
  cursor: pointer;
}

.googleWrapper {
  border: 1px solid black;
  border-radius: 5px;
  padding: 8px;
  font-size: 16px;
  max-width: 375px;
  display: block;
}

.googleSignIn {
  margin: auto;
  display: flex;
  justify-content:center;
}

.googleWrapper:hover {
  cursor: pointer;
}

.reminder {
  background-color: #C3E7F2;
  padding: 0.5rem 0rem;
  text-align: center;
  font-size: 14px;
  color: #0394ca;
  margin-top: 6px;
}

.maintenance-alert {
  background-color: rgb(233, 63, 37);
  padding: 0.5rem 0rem;
  text-align: center;
  font-size: 14px;
  color: #ffffff;
}

.reminder-link {
  color: inherit;
  text-decoration: underline !important;
}

::placeholder {
  opacity: 0.3; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  opacity: 0.3;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  opacity: 0.3;
}

@media (max-width: 768px) {
  .thisContainer {
    padding-top: 0px;
    width: 100%;
  }
  .signInLeft,
  .signInRight,
  .signUpLeft,
  .signUpRight {
    padding: 60px;
    border-radius: 0px;
  }
}
</style>
